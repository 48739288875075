import React, { useState } from "react";

export const InputColor = ({ initialValue = '#ffffff', callback = null, idSelector = '' }) => {

  const [color, setColor] = useState( initialValue );

  const handleChange = (e) => {
    setColor( e.target.value );
    if ( typeof callback === 'function' ) {
      callback( e.target.value );
    }
  }

  return <div style={{ display: 'flex', width: '100%' }}>
    <input id={idSelector || ''} style={{ width: '30%', padding: 0, height: '30px' }} type="color" value={color} onChange={handleChange} />
    <input id={idSelector ? idSelector+'-input' : ''} style={{ width: '70%' }} onChange={handleChange} value={color.toUpperCase()} />
  </div>;

}