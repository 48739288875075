export const assignmentText = {
  "conflictroute"         : "La ruta que seleccion\u00f3 tiene un conflicto, haga clic en el bot\u00f3n ver conflicto abajo.",
  "objectnounique"        : "El objeto en el que ha hecho clic, no tiene un identificador \u00fanico o ID de referencia que nos permita asignar un acceso correcto a este. Le recomendamos que agregue un identificador \u00fanico al elemento que est\u00e1 seleccionando, puede hacer clic en el bot\u00f3n de edici\u00f3n de la p\u00e1gina, para poner un id al objeto en conflicto.",
  "conflict"              : "Conflicto",
  "repeatobject1"         : "El objeto al que ha hecho clic tiene la siguiente ruta",
  "repeatobject2"         : "objetos.",
  "repeatobject3"         : "Tienes 2 soluciones: ",
  "solution1"             : "1 - Easy: Close this window and you will find a button that says Save Assignment taking into account the text. Pressured and ready.",
  "solution2"             : "2 - Better: We recommend adding a unique Identifier to the element you are selecting, you can click on the edit page button, to put a unique identifier to the objects in conflicts.",
  "saveassignment"        : "Guardar asignaci\u00f3n",
  "saveassignmenthelp"    : "El objeto que seleccion\u00f3 no tiene un Id o identificador, haga clic en el bot\u00f3n para ver conflictos.",
  "saveassignmenttext"    : "Guardar asignando al texto",
  "saveassignmenttexthelp": "El objeto que seleccion\u00f3 no tiene ning\u00fan id o identificador, haga clic en el bot\u00f3n para ver conflictos."
};
