import { isObject } from "./isObject";

export const extendsObj = (obj, ...args) => {
  args.forEach(arg => {
    for (const key in arg) {
      if (arg.hasOwnProperty(key)) {
        if (isObject(arg[key]) && isObject(obj[key])) {
          extendsObj(obj[key], arg[key]);
        } else {
          obj[key] = arg[key];
        }
      }
    }
  });
  return obj;
}