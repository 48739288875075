import React from 'react';
import './assignment.css';
import Draggable from "react-draggable";

const Assignment = props => {

  return [
    <Draggable key="dragAssignment">
      <div key='draggable' id='draggable' className='draggable row asignacion-content pauNoEventAssig'>
        <div className='s12 red'>
          The ID hotspot is: {props.hotspotId} <br/> Click on the element you want to select for the assignment and then save the assignment
          <div id='pau-selector-view'>
            <h3 style={{
              display    : 'inline-block',
            }}>Element path:</h3>
            <h4 style={{
              display    : 'inline-block',
              marginRight: '10px !important'
            }} id='pauRute'/>
            <h4 id='pauMsgConflicts'/>
            <input type='hidden' id='pauDataPath' value=''/>
            <div>
              <button className='btn-pau btn-pau-assig pauNotZoomLink' id='pauSaveAssig'>Save assignment</button>
            </div>
            <br/>
          </div>
        </div>
      </div>
    </Draggable>,
    <div key='selector' id='selector'>
      <div id='selector-top'/>
      <div id='selector-left'/>
      <div id='selector-right'/>
      <div id='selector-bottom'/>
    </div>
  ];
}

export default Assignment;
