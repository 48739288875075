import Cookies from 'js-cookie';

class PauHelpers {

  static normalize = (
    _ => {
      var from    = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç",
          to      = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc",
          mapping = {};

      for ( var i = 0, j = from.length; i < j; i++ )
        mapping[ from.charAt( i ) ] = to.charAt( i );

      return function ( str ) {
        var ret = [];
        for ( var i = 0, j = str.length; i < j; i++ ) {
          var c = str.charAt( i );
          if ( mapping.hasOwnProperty( str.charAt( i ) ) )
            ret.push( mapping[ c ] );
          else
            ret.push( c );
        }
        ret = ret.join( '' );

        const specialChars = "!@#$^&%*()+=[]/{}|:<>?.ç";

        for ( let l = 0, m = specialChars.length; l < m; l++ ) {
          ret = ret.replace( new RegExp( "\\" + specialChars[ l ], 'gi' ), '' );
        }

        return ret;

      };

    }
  )();

  static isFirefox() {

    if ( navigator.userAgent.toLowerCase().indexOf( "firefox" ) >= 0 ) return true;

    return false;

  }

  static getSettingsCookies( key = null ) {

    let settings = JSON.parse( Cookies.get( "settings" ) );

    if ( key ) {
      return settings[ key ];
    }

    return settings;

  }

  static attrToBoolean = ( attrNumber ) => Boolean( Number( attrNumber ) );

  static filterCallback( obj, predicate ) {

    Object.keys( obj )
      .filter( key => predicate( obj[ key ] ) )
      .reduce( ( res, key ) => (
        res[ key ] = obj[ key ]
      ), {} );

  }

  static filterKeys( old_object, new_object ) {

    let newObj = this.copyObject( old_object );

    for ( let key in newObj ) {

      if ( !(
        key in new_object
      ) ) {

        delete newObj[ key ];

      }

    }

    for ( let key in new_object ) {

      if ( !(
        key in old_object
      ) ) {

        newObj[ key ] = new_object[ key ];

      }

    }

    return newObj;

  };

  static copyObject( old_object ) {
    let newObj = JSON.parse( JSON.stringify( old_object ) );
    return newObj;
  }

}

export default PauHelpers;
