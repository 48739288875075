export const eventClickPauActions = ( event ) => {

  /**
   * @var MouseEvent event
   */
  if ( event.target.id.indexOf( 'selector' ) !== -1 ) {
    return;
  }

  let $target = event.target.closest( '.eventClickPauActions' )?.parentElement;

  if ( ! $target ) {
    if ( event.target.matches( '[data-key=pauReactMenus]' ) ) {
      $target = event.target.tagName === 'IMG' ? event.target.parentElement : event.target;
    } else {
      return;
    }
  }

  event.preventDefault();


  let $pauMainAccess = document.querySelector( "#pauMainAccess" ),
      // dataUrl          = $pauMainAccess.getAttribute( "data-url" ),
      // mediaDirHeader   = dataUrl + "multimedia/pau/react/header/",
      $pauBackMain   = document.querySelector( '#pauBackMain' ),
      $iconPauAccess = document.querySelector( '.iconPauAccess' ),
      // $iconPauAccessimg = document.querySelector( '.iconPauAccess img' ),
      $pauReactMenus = document.querySelectorAll( '.pauReactMenus li a' ),
      $panelActions  = document.querySelectorAll( '.panelActions > div' ),
      $pauContMain   = document.querySelector( '#pauContMain' ),
      $pauContPanel  = document.querySelector( '#pauContPanel' ),
      pauContMain    = parseInt( $pauContMain.getAttribute( "data-value" ), 10 ),
      pauContPanel   = parseInt( $pauContPanel.getAttribute( "data-value" ), 10 ),
      dataKey        = $target.getAttribute( "data-key" );

  switch ( dataKey ) {

    case "iconPauAccess" :

      $pauReactMenus.removeClass( "active" );

      if ( pauContMain === 1 ) {

        global.pauActiveElement = true;
        $target.addClass( 'pauPanelDesplegado' );

        $pauMainAccess
          .css( 'height', 'calc( 100% - 30px )' )
          .anime( {
            right: '-380px'
          } );

        $iconPauAccess.css( {
          "width"     : "80px",
          "height"    : "80px",
          "padding"   : "5px",
          "background": "#010066"
        } );

        $target.innerHTML = global.pauIconsMain.ico_pau;

        // $iconPauAccessimg.setAttribute( "src", mediaDirHeader + "ico-pau.svg" );

        $pauBackMain?.show();

        $pauContMain.setAttribute( "data-value", '0' );

      } else if ( pauContMain === 0 ) {

        global.pauActiveElement = false;
        $target.removeClass( 'pauPanelDesplegado' );

        $pauContMain.setAttribute( "data-value", '1' );

        $pauMainAccess
          .css( 'height', '80px' )
          .anime( {
            right: '-450px'
          } );

        $iconPauAccess.css( {
          "width"     : "80px",
          "height"    : "80px",
          "padding"   : "0",
          "background": "transparent"
        } );

        $target.innerHTML = global.pauIconsMain.ico_principal_pau;

        // $iconPauAccessimg.setAttribute( "src", mediaDirHeader + "ico-principal-pau.svg" );

        $pauBackMain?.hide();

      }

      break;

    case "pauReactMenus" :

      if ( pauContPanel === 1 ) {

        $pauMainAccess.anime( {
          right: '0'
        } );

        $pauContMain.setAttribute( "data-value", 0 );

      }

      $pauReactMenus.removeClass( "active" )
                    .forEach( (element) => {
                      element.setAttribute( 'aria-selected', 'false' )
                    } );

      let target = "";

      target = $target.getAttribute( "href" );
      $target.addClass( "active" )
             .setAttribute( 'aria-selected', 'true' );

      $panelActions.hide()
                  .forEach( (element) => {
                    element.setAttribute( 'aria-expanded', 'false' )
                  } );
      document.querySelector( target ).show().setAttribute( 'aria-expanded', 'true' );

      break;

    case "pauBackMain" :

      $pauReactMenus.removeClass( "active" );

      pauContMain = 1;

      $pauBackMain.hide();

      $pauMainAccess.anime( {
        right: '-430px'
      } );

      break;

    default:
      break;

  }

};
